import { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react';

export type ReadOnlyArray<T = any> = readonly T[];
export type CallbackHook = typeof useCallback | typeof useEffect | typeof useLayoutEffect | typeof useMemo;
export type HookCallback<
    TDependencyArray extends ReadOnlyArray = ReadOnlyArray,
    TArgsArray extends ReadOnlyArray = ReadOnlyArray,
    TReturn = any,
    TCallArgs extends ReadOnlyArray = ReadOnlyArray
> = (deps: TDependencyArray, args: TArgsArray, ...callArgs: TCallArgs) => TReturn;

function useArgsFactory(ch: CallbackHook) {
    return function useArgs_(c: HookCallback<any, any, any, any>, d: ReadOnlyArray, a: ReadOnlyArray) {
        const aRef = useRef(a);
        const cRef = useRef(c);

        aRef.current = a;
        cRef.current = c;

        return (ch as any)((...cta: ReadOnlyArray) => cRef.current(d, aRef.current, ...cta), d);
    };
}

/** @deprecated in favour of the simpler useCTCallback */
export function useArgsCallback<
    TDependencyArray extends ReadOnlyArray = ReadOnlyArray,
    TArgsArray extends ReadOnlyArray = ReadOnlyArray,
    TCallTimeArgs extends ReadOnlyArray = ReadOnlyArray,
    TReturn = void
>(
    callback: HookCallback<TDependencyArray, TArgsArray, TReturn, TCallTimeArgs>,
    deps: TDependencyArray,
    args: TArgsArray
): (...calltimeArgs: TCallTimeArgs) => TReturn {
    return useArgsFactory(useCallback)(callback, deps, args);
}

/** @deprecated in favour of the simpler useCTEffect */
export function useArgsEffect<
    TDependencyArray extends ReadOnlyArray = ReadOnlyArray,
    TArgsArray extends ReadOnlyArray = ReadOnlyArray
>(
    effect: HookCallback<TDependencyArray, TArgsArray, void | (() => void)>,
    deps: TDependencyArray,
    args: TArgsArray
): void {
    return useArgsFactory(useEffect)(effect, deps, args);
}

/** @deprecated in favour of the simpler useCTCallback */
export function useArgsLayoutEffect<
    TDependencyArray extends ReadOnlyArray = ReadOnlyArray,
    TArgsArray extends ReadOnlyArray = ReadOnlyArray
>(
    effect: HookCallback<TDependencyArray, TArgsArray, void | (() => void)>,
    deps: TDependencyArray,
    args: TArgsArray
): void {
    return useArgsFactory(useLayoutEffect)(effect, deps, args);
}

/** @deprecated in favour of the simpler useCTMemo */
export function useArgsMemo<
    TDependencyArray extends ReadOnlyArray = ReadOnlyArray,
    TArgsArray extends ReadOnlyArray = ReadOnlyArray,
    TReturn = any
>(factory: HookCallback<TDependencyArray, TArgsArray, TReturn>, deps: TDependencyArray, args: TArgsArray): TReturn {
    return useArgsFactory(useMemo)(factory, deps, args);
}
