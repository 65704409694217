export class ExtendedEvent extends Event {
    public eventTarget!: ControlEventTarget;
    public value: any;
    public oldValue: any;
}

export enum ControlEventTarget {
    ActiveBaseILayerKey,
    ActiveILayer,
    ActiveController,
    IsDrawing,
    ActiveTool,
    SaveStatus,
    GPSCenterError
}

export enum SaveStatus {
    Unedited,
    Ready,
    Start,
    Saving,
    Done,
    Cancel,
    Labels
}
