import version from './config.json';

// Do not change these values, use a .env file instead. An example file has been supplied.

const config = {
    ...version,
    SERVER_URL: process.env.REACT_APP_SERVER_URL ?? 'https://devapi.caretaker.dk/api',
    SERVER_URL_BASE: process.env.REACT_APP_SERVER_URL_BASE ?? 'https://devapi.caretaker.dk',
    NODE_TEST_API: process.env.REACT_APP_NODE_TEST_API ?? 'https://reactnode.caretaker.dk'
};

export default config;
