import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from './redux-base-hooks';
import { useCallback, useEffect } from 'react';

import { IDictionary } from '../utils/types';

export enum useDictionaryKeys {
    MapActive = 'map-active',
    MapLast = 'map-last',
    MapCss = 'map-css'
}

export const useDictionaryDefaults = {
    MapActive: '',
    MapLast: '',
    MapCss: '{}'
};

//#region Redux

const dictionarySlice = createSlice({
    name: 'dictionary',
    initialState: {} as IDictionary,
    reducers: {
        updateSingleState: (state: IDictionary, action: PayloadAction<{ key: string; value: any }>) => {
            state[action.payload.key] = action.payload.value;
        }
    }
});

export const dictionaryReducer = dictionarySlice.reducer;

//#endregion Redux

type updateType<T> = T | ((oldValue: T) => T);

/**
 * A hook giving access to an entry in a dictionary saved in the redux store
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 * @param key the dictionary key for the desired value
 * @returns the value
 */
function useDictionary<T = any>(key: string, defaultValue: T): [T, (newState: updateType<T>) => void] {
    const storeValue: T | undefined = useAppSelector((storeState) => storeState.dictionary[key]);
    const value: T = storeValue ?? defaultValue;
    const dispatch = useAppDispatch();

    const update = useCallback(
        (inputState: updateType<T>) => {
            const newState = typeof inputState === 'function' ? (inputState as (oldValue: T) => T)(value) : inputState;
            dispatch(dictionarySlice.actions.updateSingleState({ key, value: newState }));
        },
        [dispatch, key, value]
    );

    useEffect(() => {
        if (storeValue === undefined) update(value);
    });

    return [value, update];
}

export default useDictionary;
