import CTControl, { ICTControl } from './CTControl';

import { ControlHandler } from './ControlHandler';
import { ControlNames } from '../interfaces';
import { dawaAutocomplete as dawaA } from 'dawa-autocomplete2';
import { staticImplements } from '../../../shared/utils/decorators';
import { transform } from 'ol/proj';

//#region Search Adress In DK Commandline Function
export function searchAddress(searchInput: HTMLInputElement) {
    //kommunekode
    if (searchInput) {
        let searchAddressResult;
        dawaA(searchInput, {
            baseUrl: 'https://dawa.aws.dk',
            params: {},
            select: (res: any) => {
                searchAddressResult = res;
            }
        });
        return searchAddressResult;
    }
}

export function searchAddressByString(addr: string) {
    const url =
        'https://dawa.aws.dk/autocomplete' +
        '?q=' +
        encodeURIComponent(addr) + //Platanvej+46%2C+8680
        '&type=adresse' +
        '&caretpos=' +
        addr.length +
        '&supplerendebynavn=true' +
        '&stormodtagerpostnumre=true' +
        '&multilinje=true' +
        '&fuzzy=';

    // eslint-disable-next-line no-restricted-syntax
    return fetch(url).then((resp) => resp.json());
}
//#endregion Search Adress In DK Commandline Function

//#region Search Adress In DK Control

export interface Options {
    iconsFolder?: string;
    target?: any;
}

@staticImplements<ICTControl>()
export class SearchDKControl extends CTControl {
    public static ControlName = ControlNames.SearchDK;

    constructor(controlHandler: ControlHandler, opt_options?: Options) {
        const element = SearchDKControl.createGuiElement(controlHandler, opt_options?.iconsFolder);
        super(controlHandler, {
            element: element.div,
            target: opt_options?.target
        });

        // <div>
        //     <input type="search">
        // </div>
        dawaA(element.input, {
            baseUrl: 'https://dawa.aws.dk',
            params: {
                //kommunekode: 746
            },
            select: (selected: any) => {
                this.map.getView().setCenter(transform([selected.data.x, selected.data.y], 'EPSG:4326', 'EPSG:25832'));
            }
        });
    }

    private static createGuiElement(controlHandler: ControlHandler, iconsFolder?: string) {
        const _element: HTMLDivElement = document.createElement('div');
        _element.id = 'ol-wktControl';
        _element.className = 'ol-unselectable ol-control ol-searchDKControl';
        _element.style.top = `${controlHandler.getAssignedHeight()}px`;

        const searchBarContainer = document.createElement('div');
        searchBarContainer.className = 'ol-searchDKControl-container';

        const iconContainer = document.createElement('div');
        iconContainer.className = 'ol-searchDKControl-iconContainer';
        let icon = document.createElement('img');
        icon = document.createElement('img');
        icon.className = 'ol-searchDKControl-icon';
        icon.src = iconsFolder == null ? './icons/search.svg' : iconsFolder + 'search.svg';

        iconContainer.appendChild(icon);
        searchBarContainer.appendChild(iconContainer);

        const searchInput = document.createElement('input');
        searchInput.className = 'ol-searchDKControl-input';
        searchInput.type = 'search';

        searchBarContainer.appendChild(searchInput);

        _element.appendChild(searchBarContainer);
        return { div: _element, input: searchInput };
    }
}

//#endregion Search Adress In DK Control
