import { CenterCenterBox, WideButton } from '../../components/mui/styled-mui';

import Grid from '@mui/material/Grid/Grid';
import LoadingScreen from '../loading';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography/Typography';
import useHistoryNavigate from '../../shared/hooks/extended-hooks/use-history-navigate';

const Item = ({ children }: any) => (
    <Grid item xs={6}>
        {children}
    </Grid>
);

const Http403 = ({ awaitingPermissions }: { awaitingPermissions?: boolean }) => {
    const { navigate, goBack } = useHistoryNavigate();

    if (awaitingPermissions) return <LoadingScreen />;

    return (
        <CenterCenterBox>
            <Stack spacing={2} sx={{ ml: 2, mr: 2, height: '100%', pb: 4, maxWidth: '500px' }}>
                <Typography variant='h1' fontFamily='"StatusHeader"'>
                    403
                </Typography>
                <Typography variant='h6'>Du har desværre ikke adgang til denne side</Typography>
                <Grid container spacing={2} sx={{ pr: 4 }}>
                    <Item>
                        <WideButton onClick={() => goBack()}>Tilbage</WideButton>
                    </Item>
                    <Item>
                        <WideButton onClick={() => navigate('/')}>Til Forsiden</WideButton>
                    </Item>
                </Grid>
            </Stack>
        </CenterCenterBox>
    );
};

export default Http403;
