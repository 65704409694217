import CTControl, { ICTControl } from './CTControl';
import { ControlEventTarget, ExtendedEvent } from './ControlHandlerUtils';

import { ControlHandler } from './ControlHandler';
import { ControlNames } from '../interfaces';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import { MapEvent } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { WKT } from 'ol/format';
import { staticImplements } from '../../../shared/utils/decorators';
import { wktFormat } from './LayerImportExport';

export const toWkt = (feature: Feature<Geometry>) =>
    wktFormat.writeFeature(feature, { featureProjection: 'EPSG:25832' });

export const wktToCoords = (wkt: string) => {
    const wktArray = wkt.split('(')[1].split(')')[0].split(' ');
    return [Number(wktArray[0]), Number(wktArray[1])];
};

export interface Options {
    render?: (p0: MapEvent) => void;
    target?: HTMLElement | string;
}

@staticImplements<ICTControl>()
export class WktIEControl extends CTControl {
    private gui;
    private isVisible: boolean;
    private layer: VectorLayer<VectorSource<any>>;
    public static ControlName: string = ControlNames.WktIE;

    private static wktFormat = new WKT();

    public static getFeaturesFromLayer(layer: any) {
        return layer.getSource().getFeatures();
    }

    public static addWktFromString(layer: any, wkt: string) {
        const wktlist = wkt.split('\n');
        wktlist.forEach((f) => {
            const feature = WktIEControl.wktFormat.readFeature(f, {
                dataProjection: 'EPSG:25832',
                featureProjection: 'EPSG:25832'
            });
            layer.getSource().addFeature(feature);
        });
        return true;
    }

    public static exportWkt(layer: any) {
        const wktStrings: any[] = [];
        const features = WktIEControl.getFeaturesFromLayer(layer);
        features.forEach((f: any) => {
            wktStrings.push(WktIEControl.wktFormat.writeFeature(f));
        });
        return wktStrings.join('\n');
    }

    private dispError(errorText: string) {
        this.gui.errorImport.innerText = 'Kunne ikke tilføje WKT.\n' + errorText;
        this.gui.errorImport.classList.add('ol-wktControl-errorMsg-visible');
    }

    private static createGuiElement() {
        const div = document.createElement('div');
        div.id = 'ol-wktControl';
        div.className = 'ol-unselectable ol-wktControl'; //  ol-control

        const menu = document.createElement('div');
        menu.className = 'ol-wktControl-options';
        div.appendChild(menu);

        const exportTextarea = document.createElement('textarea');

        const btnImport = document.createElement('button');
        const textareaImport = document.createElement('textarea');
        const fileBtnImport = document.createElement('button');
        const fileImport = document.createElement('input');
        const errorImport = document.createElement('p');
        const refreshBtn = document.createElement('button');
        const btnExport = document.createElement('button');
        const aDownload = document.createElement('a');

        {
            //Import
            const div = document.createElement('div');
            div.classList.add('ol-wktControl-import');
            const h1 = document.createElement('h1');
            h1.innerText = 'Import';

            fileImport.type = 'file';
            fileBtnImport.innerText = 'Importer fra fil';
            fileBtnImport.addEventListener('click', (_) => fileImport.click());
            fileImport.addEventListener('change', (f) => {
                fileImport.files![0].text().then((t) => {
                    textareaImport.value = t;
                });
            });

            errorImport.className = 'ol-wktControl-errorMsg';

            //file.innerText = 'Importer fra fil';
            div.appendChild(h1);
            div.appendChild(textareaImport);
            //div.appendChild(file);
            div.appendChild(fileBtnImport);
            div.appendChild(btnImport);
            div.appendChild(errorImport);
            menu.appendChild(div);
        }
        {
            //Export
            const div = document.createElement('div');
            div.classList.add('ol-wktControl-export');
            const h1 = document.createElement('h1');
            h1.innerText = 'Export';

            aDownload.download = 'wktExport.txt';

            //exportTextarea.disabled = 'true';
            //textarea.innerText = '';
            const btnContainer = document.createElement('div');
            btnContainer.className = 'ol-wktControl-export-btnContainer';
            btnExport.innerText = 'Gem som fil';

            refreshBtn.className = 'ol-wktControl-export-refreshBtn';

            div.appendChild(h1);
            div.appendChild(exportTextarea);
            btnContainer.appendChild(btnExport);
            btnContainer.appendChild(refreshBtn);
            div.appendChild(btnContainer);
            menu.appendChild(div);
        }

        const btnBackground = document.createElement('div');
        btnBackground.className = 'ol-wktControl-btnBackground';

        const btn = document.createElement('div');
        btn.className = 'ol-wktControl-btn';
        btn.innerText = 'WKT';
        btnBackground.appendChild(btn);
        div.appendChild(btnBackground);

        return {
            div: div,
            errorImport: errorImport,
            btnImport: btnImport,
            textareaImport: textareaImport,
            fileBtnImport: fileBtnImport,
            fileImport: fileImport,
            btnBackground: btnBackground,
            menu: menu,
            refreshBtn: refreshBtn,
            btnExport: btnExport,
            aDownload: aDownload,
            exportTextarea: exportTextarea
        };
    }

    private changeVisibility() {
        if (this.isVisible) {
            this.gui.menu.classList.remove('ol-wktControl-options-open');
        } else {
            this.gui.menu.classList.add('ol-wktControl-options-open');
            this.controlHandler.activeController = WktIEControl.ControlName;
            this.gui.exportTextarea.value = WktIEControl.exportWkt(this.layer);
            if (this.gui.exportTextarea.value === '') {
                if (this.controlHandler.activeILayer.displayName === 'Default Drawing Layer')
                    this.gui.exportTextarea.value = 'Vælg et lag i edit menuen';
                else this.gui.exportTextarea.value = 'Der blev ikke fundet noget geometri på det valgte lag';
            }
        }
        this.isVisible = !this.isVisible;
    }

    private addEventlisteners() {
        this.gui.btnImport.innerText = 'Tilføj';
        this.gui.btnImport.addEventListener('click', (_) => {
            if (this.gui.textareaImport.value !== '') {
                if (!WktIEControl.addWktFromString(this.layer, this.gui.textareaImport.value)) {
                    this.dispError('Tekst i tekstfeldtet er ikke formateret korrekt.');
                }
            } else {
                this.dispError('Tekstfeldtet er ikke udfyldt og der er heller ikke blevet uploadet en fil.');
            }
        });

        this.gui.btnBackground.addEventListener('click', (_) => {
            this.changeVisibility();
        });

        this.gui.refreshBtn.addEventListener('click', (_) => {
            this.gui.exportTextarea.value = WktIEControl.exportWkt(this.layer);
            if (this.gui.exportTextarea.value === '') {
                this.gui.exportTextarea.value = 'Der blev ikke fundet noget geometri';
            }
        });

        this.gui.btnExport.addEventListener('click', (_) => {
            this.gui.aDownload.href = window.URL.createObjectURL(
                new Blob([this.gui.exportTextarea.value], { type: 'text/plain;charset=UTF-8' })
            );
            this.gui.aDownload.click();
        });

        this.controlHandler.addCustomEventListener('change', (e: ExtendedEvent) => {
            if (e.eventTarget === ControlEventTarget.ActiveILayer) {
                this.layer = e.value.layer as VectorLayer<VectorSource<any>>;
            } else if (
                e.eventTarget === ControlEventTarget.ActiveController &&
                e.value !== WktIEControl.ControlName &&
                this.isVisible
            ) {
                // Der er skiftet væk fra denne controller
                this.changeVisibility();
            }
        });
    }

    constructor(controlHandler: ControlHandler, opt_options?: Options) {
        const gui = WktIEControl.createGuiElement();
        super(controlHandler, {
            element: gui.div,
            render: opt_options?.render,
            target: opt_options?.target
        });
        gui.div.style.top = `${this.controlHandler.getAssignedHeight()}px`;
        this.gui = gui;
        this.layer = controlHandler.activeILayer.layer as VectorLayer<VectorSource<any>>;
        this.isVisible = false;
        this.addEventlisteners();
    }
}
