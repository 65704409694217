import CTControl, { ICTControl } from './CTControl';
import { ControlEventTarget, ExtendedEvent, SaveStatus } from './ControlHandlerUtils';
import { Draw, Modify, Select, Snap } from 'ol/interaction';
import VectorSource, { VectorSourceEvent } from 'ol/source/Vector';
import { toWkt, wktToCoords } from './WktIEControl';

import Api from '../../../shared/networking/api';
import CTLayer from './CTLayer';
import { Cluster } from 'ol/source';
import Collection from 'ol/Collection';
import { ControlHandler } from './ControlHandler';
import { ControlNames } from '../interfaces';
import { Feature } from 'ol';
import { FeatureProperty } from './feature-utility';
import Geometry from 'ol/geom/Geometry';
import { IDictionary } from '../../../shared/utils/types';
import { SqlLayer } from './SqlLayer';
import { StaticCookieHandler } from './CookieHandler';
import _ from 'lodash';
import { setEditData } from './react-controls/create-gui/create-layer-gui';
import { staticImplements } from '../../../shared/utils/decorators';

//#region Event wrappers and interfaces

/*
 * This is a collection of functions to do the introductory basics of overriding functionality of EditingControl
 */

export interface EditingControlEventWrappers {
    addfeature: addfeatureEvent;
}

export type addfeatureEvent<TGeometry extends Geometry = Geometry> = VectorSourceEvent<TGeometry> & {
    type: 'addfeature';
};
export type addfeatureCallback<TGeometry extends Geometry = Geometry> = (e: addfeatureEvent<TGeometry>) => void;

/**
 * addfeature Event wrapper that stops the default behavior and adds better typing to the event
 *
 * @param callback a function which adds the feature
 */
const addfeatureWrapper =
    (callback: addfeatureCallback) =>
    (e: any): void => {
        const event = e as addfeatureEvent;
        event.preventDefault();
        callback(event);
    };

const eventWrappers = {
    addfeature: addfeatureWrapper
};

//#region Event wrappers and interfaces

export enum Tools {
    Select = 'Select',
    Delete = 'Delete',
    Point = 'Point',
    LineString = 'LineString',
    Polygon = 'Polygon',
    Circle = 'Circle'
}

export enum ToolStyleKeys {
    Select = 'tool-style-select',
    Point = 'tool-style-point',
    LineString = 'tool-style-lineString',
    Polygon = 'tool-style-polygon',
    Circle = 'tool-style-circle'
}

export enum shapeTypes {
    Point = 'point',
    Line = 'line',
    Polygon = 'poly'
}

export interface EditingOptions {
    target?: string | HTMLElement;
}

@staticImplements<ICTControl>()
export class EditingControl extends CTControl {
    private deletedFeatures: Feature<any>[] = [];
    private movedFeatures: Feature<any>[] = [];
    private selectedLayer: SqlLayer;
    private _selectedLayerSource?: VectorSource<any>;
    private get selectedLayerSource(): VectorSource<any> | undefined {
        return this._selectedLayerSource;
    }
    private set selectedLayerSource(s: VectorSource<any> | null | undefined) {
        this._selectedLayerSource = s ?? undefined;
    }
    private sqlLayers: SqlLayer[];
    // private currentFloor: string = 'S';

    public static eventWrappers = eventWrappers;
    public static ControlName = ControlNames.Editing;

    private _currentTool: Tools = Tools.Select;
    private set currentTool(tool: Tools) {
        this.controlHandler.activeDrawTool = undefined;
        this._currentTool = tool;
        this.map.removeInteraction(this.draw);
        this.map.removeInteraction(this.snap);
        this.map.removeInteraction(this.modify);
        this.map.removeInteraction(this.select);
        this.map.removeInteraction(this.deleteSelect);
        this.addInteractions();
    }
    private get currentTool() {
        return this._currentTool;
    }

    private newIndex = 0;

    private draw!: Draw;
    private snap!: Snap;
    private modify!: Modify;
    private select!: Select;
    private deleteSelect!: Select;

    constructor(controlHandler: ControlHandler, sqlLayers: SqlLayer[], opt_options?: EditingOptions) {
        super(controlHandler, {
            element: document.createElement('div'),
            target: opt_options?.target
        });
        this.sqlLayers = sqlLayers;
        this.selectedLayer = controlHandler.activeILayer.layer as SqlLayer;
        const options = this.selectedLayer.options;
        this.selectedLayerSource =
            options?.isCluster === true
                ? (this.selectedLayer.getSource() as Cluster).getSource()
                : this.selectedLayer.getSource();

        this.addEventListeners();
        window.addEventListener('load', () => {
            this.addInteractions();
        });
    }

    //#region ----- Tools -----
    private addInteractions() {
        this.controlHandler.saveStatus = SaveStatus.Ready;
        switch (this.currentTool) {
            case 'Point':
            case 'LineString':
            case 'Polygon':
            case 'Circle':
                // !VIGTIGT! Modify skal altid loades før draw, så draw tager prioritet over click-eventet (lib bruger on, ikke addEventListener).
                // Ellers kan features ikke udvides, da OL forsøger at modificere i stedet for at tegne en ny feature i samme punkt.
                const features = new Collection(
                    this.selectedLayerSource
                        ?.getFeatures()
                        .filter((f) => (f.get(FeatureProperty.Data)?.floor ?? '') === this.controlHandler.currentFloor)
                );
                this.modify = new Modify({
                    features: features
                    // source: this.selectedLayerSource,
                });
                this.modify.on('modifystart', (e) => {
                    e.features.forEach((f) => this.movedFeatures.push(f as Feature));
                });
                this.map.addInteraction(this.modify);
                this.draw = new Draw({
                    source: this.selectedLayerSource,
                    type: this.currentTool,
                    style: this.selectedLayer.get(ToolStyleKeys[this.currentTool])
                });
                this.draw.on('drawend', (e) => {
                    // checks if a feature was clicked
                    // if yes, then it removes the feature, that was about to be added
                    // and then sends the feature to the gui, to change the label
                    // if no feature was clicked, then a new feature is added
                    const coo = wktToCoords(toWkt(e.feature));
                    const fe = this.selectedLayerSource?.getFeaturesAtCoordinate(coo);
                    if ((fe?.length ?? 0) > 0) {
                        this.selectedLayerSource?.removeFeature(e.feature);
                        fe?.forEach((f) => {
                            f.set(FeatureProperty.Data, { ...f.get(FeatureProperty.Data), new: true });
                        });
                        this.addLabels();
                        return;
                    }
                    const i = 1 + _.clone(this.newIndex);
                    this.newIndex = i;
                    e.feature.set(FeatureProperty.Data, {
                        label: i + '',
                        new: true,
                        floor: this.controlHandler.currentFloor
                    });
                    this.addLabels([e.feature]);
                });
                this.controlHandler.activeDrawTool = this.draw;

                this.map.addInteraction(this.draw);

                this.snap = new Snap({ features: features });
                this.map.addInteraction(this.snap);
                this.selectedLayer.setMinZoom(0);
                break;
            case 'Select':
                this.select = new Select({
                    layers: [this.selectedLayer],
                    style: this.selectedLayer.get(ToolStyleKeys[this.currentTool])
                });
                this.map.addInteraction(this.select);
                this.selectedLayer.setMinZoom((this.selectedLayer as SqlLayer).options?.minZoom ?? 14);
                break;
            case 'Delete':
                this.deleteSelect = new Select({ layers: [this.selectedLayer] });
                this.deleteSelect.on('select', (e) => {
                    // uses a confirm to delete the feature right away
                    // That means this is unaffected by the save button
                    (e.target as VectorSource<any>).getFeatures().forEach((f) => {
                        if (
                            window.confirm(
                                `Er du sikker på at du vil slette ${
                                    (f.getProperties().features?.[0] ?? f).get(FeatureProperty.Data)?.label ?? 'denne'
                                }?`
                            )
                        ) {
                            this.deletedFeatures.push(f.getProperties().features?.[0] ?? f);
                            this.selectedLayerSource?.removeFeature(f.getProperties().features?.[0] ?? f);
                            Api.delete(
                                `${(this.selectedLayer as CTLayer).options.url}/${
                                    (f.getProperties().features?.[0] ?? f).get(FeatureProperty.Data)?.id
                                }` ?? ''
                            );
                            (this.selectedLayer as SqlLayer).ResetLayerFeatures?.();
                        }
                    });
                });
                this.selectedLayer.setMinZoom(0);
                this.map.addInteraction(this.deleteSelect);
                break;
        }
    }
    //#endregion

    //#region ----- Save / Revert -----

    // Runs every time a feature is added or changed, to send the data to the gui
    // so the label can be edited
    // Runs when saving as well
    private addLabels(latest?: Feature<any>[]) {
        const saveArray: IDictionary[] = [];
        const features = this.selectedLayerSource?.getFeatures() ?? [];
        if (latest) latest.forEach((f) => features.push(f));
        //Lav JSON til api for hver feature
        features
            .filter((f) => f.get(FeatureProperty.Data)?.new === true)
            .forEach((f: Feature<any>) => {
                const geometry = toWkt(f);
                const data = f.get(FeatureProperty.Data) ?? {
                    label: this.newIndex++,
                    floor: this.controlHandler.currentFloor
                };

                data['geometry'] = geometry;

                saveArray.push(data);
            });
        setEditData(saveArray, this.movedFeatures);
    }

    private async saveFeatures() {
        StaticCookieHandler.saveConfigCookie();
        this.controlHandler.saveStatus = SaveStatus.Done;
        (this.selectedLayer as SqlLayer).ResetLayerFeatures?.();
        this.newIndex = 0;
        this.movedFeatures = [];
    }

    private revertFeatures() {
        this.controlHandler.saveStatus = SaveStatus.Unedited;
        (this.selectedLayer as SqlLayer).ResetLayerFeatures?.();
        this.newIndex = 0;
        this.movedFeatures = [];
    }

    //#endregion
    private addEventListeners() {
        // ControlHandler
        this.controlHandler.addCustomEventListener('change', (e: ExtendedEvent) => {
            switch (e.eventTarget) {
                case ControlEventTarget.ActiveILayer:
                    this.selectedLayer = e.value.layer as SqlLayer;
                    const options = this.selectedLayer.options;
                    this.selectedLayerSource =
                        options?.isCluster === true
                            ? (this.selectedLayer.getSource() as Cluster).getSource()
                            : this.selectedLayer.getSource();
                    break;
                case ControlEventTarget.ActiveTool:
                    this.currentTool = e.value;
                    break;
                case ControlEventTarget.SaveStatus:
                    switch (e.value) {
                        case SaveStatus.Start:
                            this.saveFeatures();
                            break;
                        case SaveStatus.Cancel:
                            this.revertFeatures();
                            break;
                        case SaveStatus.Labels:
                            this.addLabels();
                            break;
                    }
                    break;
            }
        });
    }
}
