import { CenterContainer, PaddedPaper } from '../../../mui/styled-mui';

import AppProviders from '../../../../App-providers';
import Box from '@mui/material/Box/Box';
import { ClickableFeature } from '../ClickableFeatures';
import { IDictionary } from '../../../../shared/utils/types';
import { IMapLayer } from '../../caretaker-map';
import Layer from 'ol/layer/Layer';
import Overlay from 'ol/Overlay';
import PluggableMap from 'ol/PluggableMap';
import ReportDriftErrorOverlay from '../react-controls/report-drift-error/clickable-feature-overlay';
import ServiceOverlay from '../react-controls/service/service-overlay';
import Source from 'ol/source/Source';
import { createRoot } from 'react-dom/client';
import styled from '@mui/styles/styled';
import { useMapContentMaxWith } from '../react-controls/base-control';

export interface IOverlayProps {
    map: PluggableMap;
    layer: Layer<Source, any>;
    feature: ClickableFeature;
    overlay: Overlay;

    close(): void;
}

//#region Base overlay

const OverlayPaper = styled(PaddedPaper)((theme) => ({
    pointerEvents: 'none'
}));

const OverlayBox = styled(Box)((theme) => ({
    pointerEvents: 'auto'
}));

export const BaseOverlay = ({ children }: any) => {
    const contentMaxWidth = useMapContentMaxWith();

    return (
        <CenterContainer>
            <OverlayPaper
                elevation={6}
                sx={{
                    maxWidth: contentMaxWidth
                }}
            >
                <OverlayBox>{children}</OverlayBox>
            </OverlayPaper>
        </CenterContainer>
    );
};

//#endregion Base overlay

export enum OverlayKeys {
    reportError = 'report-error',
    serviceMap = 'service-map'
}

const overlays: IDictionary<(props: IOverlayProps) => JSX.Element> = {
    [OverlayKeys.reportError]: ReportDriftErrorOverlay,
    [OverlayKeys.serviceMap]: ServiceOverlay
};

const getOverlayElement = (
    map: PluggableMap,
    layer: Layer<Source, any>,
    feature: ClickableFeature,
    overlay: Overlay
) => {
    const key = ((layer as any).options as IMapLayer)?.overlayKey;
    // Return if key doesn't exist
    if (key === undefined || !Object.values(OverlayKeys).includes(key)) return undefined;
    map.getOverlays()
        .getArray()
        .forEach((overlay) => map.removeOverlay(overlay));
    const div = document.createElement('div');
    const close = () => map.removeOverlay(overlay);
    const RenderElement = overlays[key];

    const root = createRoot(div);
    root.render(
        <AppProviders>
            <BaseOverlay>
                <RenderElement
                    {...{
                        map,
                        layer,
                        feature,
                        overlay,
                        close
                    }}
                />
            </BaseOverlay>
        </AppProviders>
    );

    return div;
};

export default getOverlayElement;
