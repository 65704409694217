import { ControlEventTarget, ExtendedEvent, SaveStatus } from './ControlHandlerUtils';

import { Draw } from 'ol/interaction';
import { ILayer } from './LayerMenuControl';
import { NotifyPropertyChanged } from './NotifyPropertyChanged';
import { Tools } from './EditingControl';

const generateExtendedEvent = (eventTarget: ControlEventTarget, value: any, oldValue: any) => {
    const event = document.createEvent('Event') as ExtendedEvent;
    event.initEvent('change', false, true);
    event.eventTarget = eventTarget;
    event.oldValue = oldValue;
    event.value = value;
    return event;
};

//#region interfaces

//#region do if bool

interface IDIBOptions {
    /**
     * number of miliseconds before the eventlistener shoould be removed no matter if the event triggered or not
     */
    timeout?: number;
}

//#region do if bool

//#endregion interfaces

export class ControlHandler extends NotifyPropertyChanged {
    /** Styrrer hvilket baggrunskort vises */
    private _activeBaseILayerKey!: string;
    /** Styrrer hvilket lag redigeres */
    private _activeILayer: ILayer;
    /** Sidste åbne cotrol */
    private _activeController!: string;
    /** Reference til EditingControls aktive tool */
    private _activeDrawTool?: Draw;
    /** Enum der Styre aktive tool. Skal et lag redigeres ændres denne bare til det korrekte tool */
    private _activeToolType!: Tools;
    /** Static version af ovenstående. Only get */
    private static _staticActiveToolType: Tools = Tools.Select;
    /** Status på gemning af redigering af nuværende lag */
    private _saveStatus: SaveStatus = SaveStatus.Unedited;
    /** id'er på slettede objekter der ikke er gemte endnu */
    private _deletedObjectIDs: Array<string> = [];
    /** Hvor vidt gps har problemer med at centrerer på brugeren */
    private _GPSCenterError: boolean = false;
    /** current floor to hide other features and disable modify and snap */
    private _currentFloor: string = 'S';
    /** offset til top af næste genererede control */
    private nextHeight: number = 7;

    // Legacy
    public saveCookie!: () => void;

    //#region Get Set events

    //#region activeBaseILayerKey

    /** Styrrer hvilket baggrunskort vises */
    get activeBaseILayerKey() {
        return this._activeBaseILayerKey;
    }
    set activeBaseILayerKey(iLayer: string) {
        if (iLayer !== this._activeBaseILayerKey) {
            const old = this._activeBaseILayerKey;
            this._activeBaseILayerKey = iLayer;
            this.dispatchCustomEvent(
                generateExtendedEvent(ControlEventTarget.ActiveBaseILayerKey, this._activeBaseILayerKey, old)
            );
        }
    }

    //#endregion activeBaseILayerKey
    //#region activeILayer

    /** Styrrer hvilket lag redigeres */
    get activeILayer() {
        return this._activeILayer;
    }
    set activeILayer(iLayer: ILayer) {
        if (iLayer !== this._activeILayer) {
            const old = this._activeILayer;
            this._activeILayer = iLayer;
            this.dispatchCustomEvent(generateExtendedEvent(ControlEventTarget.ActiveILayer, this._activeILayer, old));
        }
    }

    //#endregion activeILayer
    //#region activeController

    /** Sidste åbne control */
    get activeController() {
        return this._activeController;
    }
    set activeController(controller: string) {
        if (controller !== this._activeController) {
            this._activeController = controller;
            this.dispatchCustomEvent(
                generateExtendedEvent(ControlEventTarget.ActiveController, this._activeController, controller)
            );
        }
    }

    //#endregion activeController
    //#region activeTool

    /**
     * Reference til det aktive tools instance
     * @deprecated Ikke faktisk deprecated, men denne må aldrig ændres udenfor EditingControl
     */
    get activeDrawTool() {
        return this._activeDrawTool;
    }
    set activeDrawTool(tool: Draw | undefined) {
        this._activeDrawTool = tool;
    }

    /** Styre aktive tool. Skal et lag redigeres ændres denne bare til det korrekte tool */
    static get ActiveToolType() {
        return this._staticActiveToolType;
    }
    get activeToolType() {
        return this._activeToolType;
    }
    set activeToolType(tool: Tools) {
        if (tool !== this._activeToolType) {
            this._activeToolType = tool;
            ControlHandler._staticActiveToolType = tool;
            this.dispatchCustomEvent(generateExtendedEvent(ControlEventTarget.ActiveTool, this._activeToolType, tool));
        }
    }

    //#endregion activeTool
    //#region saveStatus

    /** Status på gemning af redigering af nuværende lag */
    get saveStatus() {
        return this._saveStatus;
    }
    set saveStatus(saveStatus: SaveStatus) {
        if (saveStatus !== this._saveStatus) {
            this._saveStatus = saveStatus;
            this.dispatchCustomEvent(generateExtendedEvent(ControlEventTarget.SaveStatus, this.saveStatus, saveStatus));
        }
    }

    //#endregion saveStatus
    //#region deletedObjectIDs

    /** id'er på slettede objekter der ikke er gemte endnu */
    get deletedObjectIDs() {
        return this._deletedObjectIDs;
    }
    set deletedObjectIDs(ids: Array<string>) {
        this._deletedObjectIDs = ids;
    }

    //#endregion deletedObjectIDs
    //#region GPS error

    /** Hvor vidt gps har problemer med at centrerer på brugeren */
    get GPSCenterError() {
        return this._GPSCenterError;
    }

    set GPSCenterError(state: boolean) {
        if (state !== this._GPSCenterError) {
            this._GPSCenterError = state;
            this.dispatchCustomEvent(generateExtendedEvent(ControlEventTarget.GPSCenterError, state, !state));
        }
    }

    //#endregion GPS error

    get currentFloor() {
        return this._currentFloor;
    }
    set currentFloor(floor: string) {
        if (floor !== this._currentFloor) {
            this._currentFloor = floor;
        }
    }

    //#endregion Get Set events
    //#region do if bool

    /**
     * Function that calls its callback if bool is true. Otherwise it calls it the first time bool turns true
     *
     * @param bool The controlhandler bool which decides wether to call callback
     * @param target The controlEventTarget to look out for
     * @param callback Function to call on trigger
     * @param options Optional features like fx a timeout
     * @returns the input bool
     */
    public doIfBool(bool: boolean, target: ControlEventTarget, callback: () => void, options?: IDIBOptions) {
        // Hvis true: Trigger callback og returnér
        if (bool) {
            callback();
        }

        // Ellers: tilføj eventlistener og kald callback ved event
        else {
            // eventets fulde callback
            const internal_callback = (e: ExtendedEvent) => {
                if (e.eventTarget === target) {
                    callback();
                }
            };

            // Sæt eventlistener der kun trigger én gang
            this.addCustomEventListener('change', internal_callback, { onlyOnce: true });

            // Fjern eventlistener hvis timeout løber ud
            if (options?.timeout !== undefined && options.timeout > 0) {
                new Promise<void>((resolve) =>
                    setTimeout(() => {
                        this.removeCustomEventListener('change', internal_callback);
                        resolve();
                    }, options.timeout)
                );
            }
        }

        return bool;
    }

    //#endregion do if bool

    /** få offset til top af næste genererede control */
    public getAssignedHeight(nextOffset = 39) {
        const height = this.nextHeight;
        this.nextHeight += nextOffset;
        return height;
    }

    constructor(iLayer: ILayer) {
        super();
        this._activeILayer = iLayer;

        // Bindings
        this.getAssignedHeight.bind(this);
    }
}
