import Control, { Options } from 'ol/control/Control';

import { ControlHandler } from './ControlHandler';

export type OlControlOptions = Options;

/**
 * The public fields on an openlayers control
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 */
type ICTControlPublic = CTControl;

/**
 * The public static fields on an openlayers control
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 */
interface ICTControlStatic {
    /** Constructor */
    new (controlHandler: ControlHandler, ...args: any): ICTControlPublic;

    /** A name used to refer to this type of controller */
    ControlName: string;
}

/**
 * An interface all children of CTControl are expected to implement
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 */
export type ICTControl = ICTControlStatic;

/**
 * Parent class for Openlayers Controls
 *
 * Children are expected to implement the ICTControl interface with the following decorator:
 *
 * ```ts
 * staticImplements<ICTControlStatic>()
 * ```
 *
 * Remember to add the @ sign in front
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 */
export default class CTControl extends Control {
    /** Get currently attached map. Can be assumed to be defined outside of constructors */
    protected get map() {
        return this.getMap()!;
    }
    private _controlHandler: ControlHandler;
    protected get controlHandler() {
        return this._controlHandler;
    }

    constructor(controlHandler: ControlHandler, options: OlControlOptions) {
        super(options);
        this._controlHandler = controlHandler;
    }
}
