import { IBaseContext, IBaseProps } from './interfaces';
import { createContext, useContext } from 'react';

import useSessionState from '../hooks/extended-hooks/use-session-state';

const TContext = createContext<IBaseContext<any>>({
    value: undefined,
    setValue: () => undefined
});

export default function useTContext<T = any>() {
    return useContext(TContext) as IBaseContext<T>;
}

export const TProvider = ({ children, defaultValue, storageKey }: IBaseProps<any>) => {
    const [value, setValue] = useSessionState(defaultValue, storageKey);

    return <TContext.Provider value={{ value, setValue }}>{children}</TContext.Provider>;
};
