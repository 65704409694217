import { CSSProperties, useRef, useState } from 'react';
import useDictionary, { useDictionaryDefaults, useDictionaryKeys } from '../../../../shared/hooks/redux-use-dictionary';
import useStateObject, { IStateObject } from '../../../../shared/hooks/extended-hooks/use-state-object';

import AnimationHelper from '../AnimationHelper';
import AppProviders from '../../../../App-providers';
import { ControlHandler } from '../ControlHandler';
import Grow from '@mui/material/Grow/Grow';
import Paper from '@mui/material/Paper/Paper';
import { SxProps } from '@mui/material/styles';
import useControlToggler from '../react-hooks/use-control-toggler';

//#region Base Control Area

interface IBCAProps {
    id: string;
    controlHandler: ControlHandler;

    children: JSX.Element | JSX.Element[];
}

export const BaseControlArea = ({ id, controlHandler, children }: IBCAProps) => {
    // Sikrer at dette kun sker én gang og altid gemmes.
    const [offset] = useState(() => controlHandler.getAssignedHeight());

    const areaStyle: CSSProperties = {
        top: `${offset}px`,
        display: 'flex',
        justifyContent: 'end',
        WebkitJustifyContent: 'flex-end'
    };

    return (
        <div id={id} className='ol-unselectable ol-buildingControl' style={areaStyle}>
            {children}
        </div>
    );
};

//#endregion Base Control Area
//#region Base Control Content

interface IBCCProps {
    isOpen: boolean;

    children: JSX.Element | JSX.Element[];
}

// mapwidth minus the width and left/right properties of the controls and zoom buttons
// calc(100% - 0.5em - 0.5em - 39px - 31px)
export const contentMaxWidth = 'calc(100% - 1em - 70px)';
export const useMapContentMaxWith = () => {
    const [mapCssString] = useDictionary<string>(useDictionaryKeys.MapCss, useDictionaryDefaults.MapCss);
    const mapCss: CSSProperties = JSON.parse(mapCssString);
    return `calc(${mapCss.width ?? '100%'} - ${contentLeft} - ${contentRight})`;
};

// space to the left of content
export const contentLeft = 'calc(0.5em + 39px)';
//Space to the right of content
export const contentRight = 'calc(0.5em + 31px)';

export const BaseControlContent = ({ children, ...props }: IBCCProps) => {
    const contentMaxWidth = useMapContentMaxWith();

    const paperRef = useRef<HTMLDivElement>(null);
    const sx: SxProps = {
        textAlign: 'start',
        pointerEvents: 'visible',
        padding: '7px',
        overflow: 'hidden auto',
        marginRight: '4px',
        backgroundColor: '#fff',
        maxWidth: `min(600px, ${contentMaxWidth})`,
        borderRadius: '10px'
    };

    return (
        <Grow in={props.isOpen} timeout={AnimationHelper.time}>
            <Paper ref={paperRef} sx={sx} elevation={3}>
                {children}
            </Paper>
        </Grow>
    );
};

//#endregion Base Control Content
//#region Base Control Button

interface IBCBProps {
    text?: string;
    iconPath?: string;
    icon?: any;
    onClick(): void;
}

const buttonOuterStyle: CSSProperties = {
    height: '31px'
};

const buttonInnerStyle: CSSProperties = {
    display: 'inline-block',
    cursor: 'pointer',
    // paddingTop: '2px',
    // paddingLeft: '1.5px',
    padding: '1px'
};

export const BaseControlButton = ({ text, iconPath, onClick, icon }: IBCBProps) => {
    return (
        <div className='ol-buildingControl-btnBackground' style={buttonOuterStyle}>
            <div className='ol-buildingControl-btn' style={buttonInnerStyle} onClick={onClick}>
                {icon}
                {text}
                {iconPath && <img alt='Helpdesk' src={iconPath} />}
            </div>
        </div>
    );
};

//#endregion Base Control Button
//#region Base Control

interface IBaseControlProps {
    /**
     * HTML ID for the outer div
     */
    id: string;
    /**
     * The name of the controller
     */
    name: string;
    /**
     * Controlhandler to handle events
     */
    controlHandler: ControlHandler;

    /**
     * props for the control's button in the list of controls
     */
    buttonProps: Omit<IBCBProps, 'onClick'>;

    /**
     * Optional state object to control wether the control is open or not
     */
    openState?: IStateObject<boolean>;

    /**
     * Should be set true if the component is already wrapped in an app provider, to avoid double wrapping
     */
    isProvided?: boolean;

    /**
     * React DOM children
     */
    children: JSX.Element | JSX.Element[];
}

/**
 * A basic control object for OpenLayers maps
 *
 * @param props an IBaseControlProps object
 * @returns The shell of a control object
 */
const ProvidedBaseControl = ({ children, openState, ...props }: IBaseControlProps) => {
    const _open = useStateObject(false);
    const open = openState ?? _open;

    // Toggler for control visiblity, reacting to other controls
    useControlToggler(props.name, open, props.controlHandler);

    const handleClick = () => open.setState((o) => !o);

    return (
        <BaseControlArea {...props}>
            <BaseControlContent isOpen={open.state}>{children}</BaseControlContent>
            <BaseControlButton onClick={handleClick} {...props.buttonProps} />
        </BaseControlArea>
    );
};

const BaseControl = (props: IBaseControlProps) => {
    if (props.isProvided ?? false) return <ProvidedBaseControl {...props} />;
    return (
        <AppProviders>
            <ProvidedBaseControl {...props} />
        </AppProviders>
    );
};

export default BaseControl;

//#endregion Base Control
