import { useArgsEffect } from './use-args';

interface IOptions {
    prelog?: string;
    postlog?: string;
    shouldAlert?: boolean;
}

const useStateLogger = (state: any, name?: string, { prelog, postlog, shouldAlert }: IOptions = {}) => {
    useArgsEffect(
        ([state], [name, prelog, postlog, shouldAlert]) => {
            console.log(`${name}: ` ?? '', prelog ?? '', state, postlog ?? '');
            if (shouldAlert) {
                let message;
                try {
                    message = JSON.stringify(state);
                } catch (_) {
                    message = "Can't alert non-stringifyable object";
                }
                window.alert(message);
            }
        },
        [state] as const,
        [name, prelog, postlog, shouldAlert] as const
    );
};

export default useStateLogger;
