type unsafe = (...args: ReadonlyArray<any>) => any;
type unsafeAsync = (...args: ReadonlyArray<any>) => Promise<any>;

export const safe =
    <T extends unsafe>(func: T) =>
    (...args: Parameters<T>) => {
        try {
            return {
                error: null,
                result: func(...args)
            };
        } catch (e: any) {
            return {
                error: e,
                result: null
            };
        }
    };

export const safeAsync =
    <T extends unsafeAsync>(func: T) =>
    async (...args: Parameters<T>) => {
        try {
            return {
                error: null,
                result: await func(...args)
            };
        } catch (e: any) {
            return {
                error: e,
                result: null
            };
        }
    };
