import { CallbackHook, ReadOnlyArray } from './use-args';
import { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';

import useStable from './use-stable';

type CTCallback<T = void, TArgs extends ReadOnlyArray = ReadOnlyArray> = (...args: TArgs) => T;

const useCTFactory = (hook: CallbackHook) =>
    function useCT(callback: CTCallback<any, any>, deps?: ReadOnlyArray) {
        const callbackRef = useStable(callback);
        return (hook as any)((...args: ReadOnlyArray) => callbackRef.current(...args), deps);
    };

/**
 * Mirror of useCallback which allows non-reactive dependencies.
 *
 * This allows the use of variables declared outside of the callback, which should be used but shouldn't trigger a recalculation
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 * @param callback the callback to safe
 * @param deps dependencies to trigger recalculate
 * @returns a memoized callback
 */
export function useCTCallback<TReturn = void, TArgs extends ReadOnlyArray = ReadOnlyArray>(
    callback: CTCallback<TReturn, TArgs>,
    deps?: ReadOnlyArray
): CTCallback<TReturn, TArgs> {
    return useCTFactory(useCallback)(callback, deps);
}

/**
 * Mirror of useEffect which allows non-reactive dependencies.
 *
 * This allows the use of variables declared outside of the effect, which should be used but shouldn't trigger a recalculation
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 * @param effect the effect
 * @param deps dependencies to trigger effect
 */
export function useCTEffect(effect: CTCallback<void | VoidFunction, never>, deps?: ReadOnlyArray): void {
    return useCTFactory(useEffect)(effect, deps);
}

/**
 * Mirror of useLayoutEffect which allows non-reactive dependencies.
 *
 * This allows the use of variables declared outside of the effect, which should be used but shouldn't trigger a recalculation
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 * @param effect the effect
 * @param deps dependencies to trigger effect
 */
export function useCTLayoutEffect(effect: CTCallback<void | VoidFunction, never>, deps?: ReadOnlyArray) {
    return useCTFactory(useLayoutEffect)(effect, deps);
}

/**
 * Mirror of useMemo which allows non-reactive dependencies.
 *
 * This allows the use of variables declared outside of the factory, which should be used but shouldn't trigger a recalculation
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 * @param callback the callback to safe
 * @param deps dependencies to trigger recalculate
 * @returns a memoized callback
 */
export function useCTMemo<T = unknown>(factory: CTCallback<T, never>, deps: ReadOnlyArray) {
    return useCTFactory(useMemo)(factory, deps);
}
