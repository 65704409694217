import { BygLayer } from '../../BygLayer';
import CTLayer from '../../CTLayer';
import { EnhLayer } from '../../EnhLayer';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import { IDictionary } from '../../../../../shared/utils/types';
import { IMapLayer } from '../../../caretaker-map';
import { LokLayer } from '../../LokLayer';
import PinLayer from '../../PinLayer';
import RenderFeature from 'ol/render/Feature';
import { SqlLayer } from '../../SqlLayer';

/** Names for the map's search modes. Use the searchModeNames array for mapping enum to display string */
export enum SearchMode {
    Feature,
    Address
}
export const searchModeNames = ['Feature', 'Adresse'] as const;

export interface GuiProps {
    onEnterKey: ContentProps['onEnterKey'];
    onSearchStringChange: ContentProps['onSearchStringChange'];
    onSelectionChange: ContentProps['onSelectionChange'];
    source: ContentProps['source'];
}

export interface ContentProps {
    onEnterKey(): boolean;
    onSearchStringChange(s: string): void;
    onSelectionChange(selection: ISearchSource): boolean;
    source: ISearchSource[];
}

export interface IGroupedLayers {
    enh: EnhLayer[];
    byg: BygLayer[];
    lok: LokLayer[];
    del: SqlLayer[];
    pin: PinLayer[];
    pol: CTLayer[];
}

export interface IFadeProtectedIds {
    enh: number[];
    byg: number[];
    lok: number[];
    del: number[];
}

export interface ISearchFeatureData {
    enhId: number;
    bygId: number;
    lokId: number;
    delId: number;
}

export type o_ISearchSourceCollection = IDictionary<ISearchSource[]>;

export type ISearchSourceCollection = IDictionary<IDictionary<ISearchSource[]>, IMapLayer['type']>;

export interface ISearchSource {
    featureId: string | number;
    display: string;
    searchstring: string;
    parents?: {
        enhId?: string | number;
        bygId?: string | number;
        lokId?: string | number;
        delId?: string | number;
    };
}

export type UnionFeature = RenderFeature | Feature<Geometry>;

/** Not exhaustive */
export interface DawaAdresse {
    data: {
        id: string;
        vejnavn: string;
        husnr: string;

        /** Fx "Gl. Rye" */
        supplerendebynavn: string;
        postnr: string;
        /** Fx "Ry" */
        postnrnavn: string;
        x: number;
        y: number;
    };
    type: 'adresse' | 'adgangsadresse';
    tekst: string;
    forslagstekst: string;
}

export interface DawaVejnavn {
    data: {
        navn: string;
    };
    type: 'vejnavn';
    tekst: string;
    forslagstekst: string;
}

export type DawaSearch = DawaAdresse | DawaVejnavn;
