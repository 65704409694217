import { ExtendedEvent } from '../../components/map/src/ControlHandlerUtils';
import { NotifyPropertyChanged } from '../../components/map/src/NotifyPropertyChanged';
import { safe } from '../utility';
import { useCTEffect } from '../../shared/hooks/use-ct';
import useHistoryNavigate from '../../shared/hooks/extended-hooks/use-history-navigate';

const handler = new NotifyPropertyChanged();

const changeRoute = (route: string) => {
    const e = new ExtendedEvent('change:route');
    e.value = route;
    handler.dispatchCustomEvent(e);
};

export const useCliRoute = () => {
    const history = useHistoryNavigate();

    useCTEffect(() => {
        handler.addCustomEventListener('change:route', (e) => {
            history.navigate(e.value);
        });
    }, []);
};

export const route = {
    changeRoute: safe(changeRoute)
};

export default route;
