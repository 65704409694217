import CTControl, { OlControlOptions } from '../CTControl';

import { Cluster } from 'ol/source';
import { ControlHandler } from '../ControlHandler';
import { DrawEvent } from 'ol/interaction/Draw';
import Feature from 'ol/Feature';
import { Fn } from '../../../../shared/utils/types';
import Geometry from 'ol/geom/Geometry';
import Layer from 'ol/layer/Layer';
import Source from 'ol/source/Source';
import VectorSource from 'ol/source/Vector';
import { addfeatureCallback } from '../EditingControl';

/**
 * Optionals for ReactControl
 */
interface IOptions {
    /**
     * Set if logic using a layer should always use a specific layer
     *
     * @default controlHandler.activeILayer
     */
    layer?: Layer<Source, any>;

    /**
     * Treat source as a cluster when adding or removing features
     */
    isCluster?: boolean;

    /**
     * Function to call during the addfeature event, to make sure the feature is new, before triggering the callback
     * Useful because openlayers removes and re-adds features on zoom, triggering the addfeature event
     */
    // isFeatureNew?: (feature: Feature<Geometry>) => boolean
}

export default class ReactControl extends CTControl {
    private options: IOptions;
    private get layer() {
        return this.options.layer ?? this.controlHandler.activeILayer.layer;
    }
    private get drawSource() {
        return this.layer.getSource() as VectorSource<any> | Cluster;
    }
    private get source() {
        return this.options.isCluster
            ? (this.layer.getSource() as Cluster).getSource()
            : (this.layer.getSource() as VectorSource<any>);
    }

    constructor(controlHandler: ControlHandler, options: IOptions, olControlOptions: OlControlOptions) {
        super(controlHandler, olControlOptions);
        this.options = options;

        // DEEP MERGING OPTIONS DEEP MERGES ALL THE PROPERTIES OF THE LAYER TOO, WHICH CREATES A NEW LAYER WITH THE SAME PORPERTIES
        // DOES NOT WORK!
        // this.options = __.deepMerge(defaultOptions, options);

        this.addFeature = this.addFeature.bind(this);
        this.removeFeature = this.removeFeature.bind(this);
        this.addFeatures = this.addFeatures.bind(this);
        this.removeFeatures = this.removeFeatures.bind(this);

        this.addDrawListener = this.addDrawListener.bind(this);

        this.addAddFeatureEventListener = this.addAddFeatureEventListener.bind(this);
        this.removeAddFeatureEventListener = this.removeAddFeatureEventListener.bind(this);
    }

    //#region Add / Remove Features

    /**
     * Adds a feature to the layer
     * @param feature Feature to add
     * @returns a function to remove the added feature again
     */
    protected addFeature(feature: Feature<Geometry>) {
        this.source?.addFeature(feature);
        return () => this.removeFeature(feature);
    }

    /**
     * Removes a feature from the layer
     * @param feature Feature to remove
     * @returns a function to add the removed feature again
     */
    protected removeFeature(feature: Feature<Geometry>) {
        this.source?.removeFeature(feature);
        return () => this.removeFeature(feature);
    }

    /**
     * Adds an array of features to the layer
     * @param features Features to add
     * @returns a function to remove the features again
     */
    protected addFeatures(features: Feature<Geometry>[]) {
        this.source?.addFeatures(features);
        return () => this.removeFeatures(features);
    }

    /**
     * Removes an array of features from the layer
     * @param features Features to remove
     * @returns a function to add the removed features again
     */
    protected removeFeatures(features: Feature<Geometry>[]) {
        for (const f of features) {
            this.removeFeature(f);
        }
        return () => this.addFeatures(features);
    }

    //#endregion Add / Remove Features
    //#region Add / Remove Event listeners

    //#region ControlHandler Tool Events

    protected addDrawListener(type: drawEventType, callback: drawEventCallback, options?: drawEventOptions) {
        // const tool = this.controlHandler.activeDrawTool;
        // if (tool === undefined) return;
        if (this.controlHandler.activeDrawTool === undefined) return;

        // const getEventFunc = () => {
        //     if (options?.once === true)
        //         return tool.once;
        //     return tool.on;
        // }
        if (options?.once === true) {
            this.controlHandler.activeDrawTool.once(type, callback);
            return;
        }

        this.controlHandler.activeDrawTool.on(type, callback);
        return;

        // const eventFunc = getEventFunc();
        // eventFunc(type, callback);
    }

    //#endregion ControlHandler activeTool Events
    //#region addFeature event

    /**
     * Function to add an eventlistener on the addfeature event
     *
     * Importantly, as openlayers removes and re-adds features on zoom, this event triggers a lot with existing features
     *
     * @param callback the event handler
     * @returns a function which removes the listener
     */
    protected addAddFeatureEventListener(callback: addfeatureCallback) {
        this.drawSource.addEventListener('addfeature', callback as Fn);
        return () => this.removeAddFeatureEventListener(callback as Fn);
    }

    /**
     * Function to remove an eventlistener on the addfeature event
     *
     * @param callback the event handler
     * @returns a function which add the listener back again
     */
    protected removeAddFeatureEventListener(callback: addfeatureCallback) {
        this.drawSource.removeEventListener('addfeature', callback as Fn);
        return () => this.addAddFeatureEventListener(callback as Fn);
    }

    //#endregion addFeature event

    //#endregion Add / Remove Event listeners
}

//#region function types

// add/remove feature
export type addFeature = (feature: Feature<Geometry>) => removeAddedFeature;
export type removeFeature = (feature: Feature<Geometry>) => addRemovedFeature;
export type addRemovedFeature = () => removeAddedFeature;
export type removeAddedFeature = () => addRemovedFeature;

// add/remove features
export type addFeatures = (feature: Feature<Geometry>[]) => removeAddedFeatures;
export type removeFeatures = (feature: Feature<Geometry>[]) => addRemovedFeatures;
export type addRemovedFeatures = () => removeAddedFeatures;
export type removeAddedFeatures = () => addRemovedFeatures;

// General Event types
export type addRemovedListener = () => removeAddedListener;
export type removeAddedListener = () => addRemovedListener;

// Controlhandler draw tool events
export interface drawEventOptions {
    once?: boolean;
}
export type drawEventType = 'drawstart' | 'drawend' | 'drawabort';
export type drawEventCallback = (e: DrawEvent) => void;
export type addDrawListener = (type: drawEventType, callback: drawEventCallback, options?: drawEventOptions) => void;

// addfeature events
export type addAddFeatureEventListener = (callback: addfeatureCallback) => removeAddedListener;
export type removeAddFeatureEventListener = (callback: addfeatureCallback) => addRemovedListener;

//#endregion function types
