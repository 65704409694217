import { useCallback, useState } from 'react';

import _ from 'lodash';

/**
 * useState storing the state in sessionstorage for persistence
 *
 * @param initialState Serializable state
 * @param key sessionstorage key
 * @returns an array with the state and a state setter
 */
// T extends {} stops function taking null | undefined as type param
function useSessionState<T extends {}>(
    initialState: T,
    key?: string
): [T, (newState: T | ((prevState: T) => T)) => void];

function useSessionState<T extends {}>(
    initialState: T | undefined,
    key?: string
): [T | undefined, (arg0: T | undefined | ((prevState: T | undefined) => T | undefined)) => void];

function useSessionState<T extends {}>(initialState: T | undefined = undefined, key?: string) {
    const lazyLoadInitalState = () => {
        const sessionState = key === undefined ? null : window.sessionStorage.getItem(key);
        if (sessionState != null)
            try {
                return JSON.parse(sessionState) as T;
            } catch (err) {
                return;
            }
        return _.isFunction(initialState) ? initialState() : initialState;
    };

    const [state, setState] = useState<T | undefined>(lazyLoadInitalState);

    const setNewState = useCallback(
        (newState: T | undefined | ((prevState: T | undefined) => T | undefined)) => {
            setState((c) => {
                let _newState = newState;
                if (_.isFunction(newState)) _newState = newState(c);
                key !== undefined && window.sessionStorage.setItem(key, JSON.stringify(_newState));
                return _newState as T | undefined;
            });
        },
        [key]
    );

    return [state, setNewState];
}

export default useSessionState;
