import Box, { BoxProps } from '@mui/material/Box';
import Grid, { GridProps } from '@mui/material/Grid';
import MuiMenu, { MenuProps } from '@mui/material/Menu/Menu';
import { ReactNode, forwardRef } from 'react';

import Divider from '@mui/material/Divider/Divider';
import __ from '../../shared/utils/lodash-expansions';
import styled from '@mui/styles/styled';

//#region Boxes

//#region Square

const _SquareContainer = styled(Box)(() => ({
    display: 'inline-block',
    position: 'relative',
    width: '100%'
}));

const _SquareDummy = styled(Box)(() => ({
    marginTop: '100%'
}));

const _SquareElement = styled(Box)(() => ({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
}));

/**
 * Mui Box styled as a square
 * Size depends on the set width
 *
 * All Box props are forwarded to the underlying component
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 * @returns A square box
 */
export const Square = forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
    return (
        // eslint-disable-next-line react/jsx-pascal-case
        <_SquareContainer ref={ref} {...__.copyOmit(props, 'children')}>
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <_SquareDummy />
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <_SquareElement>{props.children}</_SquareElement>
        </_SquareContainer>
    );
});

//#endregion Square
//#region RatioBox

interface RatioBoxProps {
    /**
     * The height to width ratio
     *
     * For an upright, A4 sized paper:
     * @example <RatioBox ratio={1.14142}>
     * </RatioBox>
     */
    ratio: number;
    children: ReactNode | ReactNode[];
}

/**
 * Mui Boxes combined to result in the given ratio
 * Total size depends on parent width
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 * @returns A ratio box
 */
export const RatioBox = forwardRef<HTMLDivElement, RatioBoxProps>((props, ref) => {
    return (
        <Box
            sx={{
                height: 0,
                paddingTop: `${props.ratio * 100}%`,
                paddingLeft: '100%',
                position: 'relative'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden'
                }}
            >
                {props.children}
            </Box>
        </Box>
    );
    // return (
    //     <_RatioContainer ref={ref}>
    //         <_RatioElement>
    //             {props.children}
    //         </_RatioElement>
    //     </_RatioContainer>
    // );
});

// export const RatioBox = forwardRef<HTMLDivElement, RatioBoxProps>((props, ref) => {
//     return (
//         <_RatioContainer ref={ref}>
//         </_RatioContainer>
//     );
// });

//#endregion RatioBox

//#endregion Boxes
//#region Grid

//#region Grid Item Center

const _GridItemCenter_HorizontalCenter = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: '100%'
}));

/**
 * Mui Grid item which centers its children within it
 *
 * All Grid props are forwarded to the underlying component
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 * @returns A Grid item
 */
export const GridItemCenter = forwardRef<HTMLDivElement, GridProps>((props, ref) => {
    return (
        <Grid ref={ref} item {...__.copyOmit(props, 'children')}>
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <_GridItemCenter_HorizontalCenter>{props.children}</_GridItemCenter_HorizontalCenter>
        </Grid>
    );
});

//#region Grid Item Center

export const VerticalGridDivider = ({
    hideOn,
    columns = 12,
    xs,
    sm,
    md,
    lg,
    xl
}: {
    hideOn?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    columns?: number;
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
}) => {
    const display = hideOn !== undefined ? { [hideOn]: 'none' } : undefined;

    return (
        <Box
            component={Grid}
            display={display}
            item
            xs={xs ?? columns}
            sm={sm ?? columns}
            md={md ?? columns}
            lg={lg ?? columns}
            xl={xl ?? columns}
        >
            <Divider />
        </Box>
    );
};

//#endregion Grid

export const Menu = (props: MenuProps) => (
    <MuiMenu
        {...props}
        PaperProps={{
            ...props.PaperProps,
            elevation: props.PaperProps?.elevation ?? 0,
            sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                ...props.PaperProps?.sx,
                '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                    ...(props.PaperProps?.sx as any)?.['& .MuiAvatar-root']
                },
                '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                    ...(props.PaperProps?.sx as any)?.['&:before']
                }
            }
        }}
        transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
            ...props.transformOrigin
        }}
        anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
            ...props.anchorOrigin
        }}
    />
);
